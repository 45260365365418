.weekday {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  background-color: rgb(241,243,244);
  color: rgb(128,134,139);
  margin-right: 8px;
  cursor: pointer;
  user-select: none;
}

.weekday:hover {
  background-color: rgb(241,243,244);
}

.weekday.checked {
  background-color: #1976d2;
  color: #FFF;
}

.weekday.checked:hover {
  background-color: #1976d2;
}