.stopScroll .mbsc-schedule-grid-scroll {
  overflow-y: hidden;
  padding-right: 13px;
  background-color: #FAFAFA;
}

.stopScroll .mbsc-flex.mbsc-flex-1-1 {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
}

.scrollY .mbsc-schedule-grid-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}