.wrapper-fullscreen {
  height: calc(100% - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}

.wrapper-flex-fullscreen {
  height: calc(100%);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-bottom: 20px;
}